import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { Heading, Text } from 'components/v2/Typography';
import { themes, utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { momentFormatted } from 'kb-shared/utilities/momentHelpers';

import { CYCLE_VISIT_DAYS } from '../Dashboard.graphql';
import { CycleVisitDaysResponse } from '../Dashboard.types';
import { calculateNextCycleVisitDate } from './CycleVisit.mapper';
import { CycleVisitContainer, CycleVisitTextContainer } from './CycleVisit.styled';

export const CycleVisit = (): JSX.Element | null => {
  const { cycleVisitId } = utils;
  const history = useHistory();
  const { data, loading } = useQuery<CycleVisitDaysResponse>(CYCLE_VISIT_DAYS, {
    onError: error => BugTracker.notify(error, 'Cycle Visit Days Fetch')
  });

  if (loading) return null;

  const nextCycleVisit = calculateNextCycleVisitDate(data?.cycleVisitDays, data?.cycleVisits);

  if (!nextCycleVisit) return null;

  const dateFormatted = momentFormatted(nextCycleVisit, 'M/D');

  const navigateToBookingQuiz = () =>
    history.push(`/book?step=location&appointment_id=${cycleVisitId()}&date=${nextCycleVisit}`);

  return (
    <CycleVisitContainer>
      <CycleVisitTextContainer>
        <Heading styledAs="h2" color={themes.colors.semantic.pink} noMargin>
          Schedule Your Next Cycle Visit!
        </Heading>
        <Text size="lg" color={themes.colors.semantic.pink}>
          Please schedule your next cycle visit for{' '}
          <Text color={themes.colors.white}>{dateFormatted}</Text>
        </Text>
      </CycleVisitTextContainer>
      <Button
        label={`Schedule Visit for ${dateFormatted}`}
        onClick={navigateToBookingQuiz}
        category="primary"
      />
    </CycleVisitContainer>
  );
};
