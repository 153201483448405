import React from 'react';

import { Calendar, Clock, Pin } from 'assets/icons/Icons';
import Initials from 'components/Initials';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { Appointment } from 'kb-shared';
import { shouldDisplayProvider } from 'kb-shared/utilities/appointment.helper';
import { momentFormatted } from 'kb-shared/utilities/momentHelpers';
import { useBreakpoints } from 'utilities/useBreakpoints';

import {
  CheckIcon,
  Container,
  Date,
  DateAndTime,
  HeaderMobile,
  LisItemMobile,
  ListHeader,
  ListItem,
  ListItemInfo,
  QuestionIcon,
  Time
} from './List.styled';

export const List = ({
  appointments,
  onRescheduledClick
}: {
  appointments: Appointment[];
  onRescheduledClick?: (appointment: Appointment) => void;
}) => {
  const { isMobile, isTablet } = useBreakpoints();

  if (isMobile || isTablet) {
    return (
      <Container>
        {appointments.map(item => (
          <LisItemMobile key={item.id}>
            <HeaderMobile>
              <Initials name={item.providerName} size="md" />
              <div>
                <Text tag="p" fontStyle="semibold" multilineEllipses={2}>
                  {item.closureStatus === 'checked_out' && <CheckIcon />}
                  {item.closureStatus === 'no_show' && <QuestionIcon />}
                  {item.appointmentType.name}
                </Text>
                <Text>with</Text> <Text fontStyle="semibold">{item.providerName}</Text>
              </div>
            </HeaderMobile>
            <DateAndTime>
              <Date>
                <Calendar type="regular" />{' '}
                <Text tag="time">{momentFormatted(item.startTime, 'ddd, MMM D')}</Text>
              </Date>
              <Time>
                <Clock type="regular" />
                <Text tag="time">{momentFormatted(item.startTime, 'hh:mm A')}</Text>
              </Time>
            </DateAndTime>
            {onRescheduledClick && (
              <Button
                label="Reschedule"
                category="danger"
                onClick={() => onRescheduledClick(item)}
              />
            )}
          </LisItemMobile>
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <ListHeader>
        <Text size="sm">Name</Text>
        <Text size="sm">Date and Time</Text>
        <Text size="sm">Location and Provider</Text>
      </ListHeader>
      {appointments.map(item => (
        <ListItem key={item.id}>
          <Text multilineEllipses={2}>
            {item.closureStatus === 'checked_out' && <CheckIcon />}
            {item.closureStatus === 'no_show' && <QuestionIcon />}
            {item.appointmentType.name}
          </Text>
          <ListItemInfo>
            <Calendar type="regular" />
            <Text tag="time">{momentFormatted(item.startTime, 'ddd, MMM D')}</Text>
            <Clock type="regular" />
            <Text tag="time">{momentFormatted(item.startTime, 'hh:mm A')}</Text>
          </ListItemInfo>
          <ListItemInfo>
            <Pin type="regular" />
            <Text multilineEllipses={1}>{item.location.name}</Text>
            {shouldDisplayProvider(item) && item.providerName && (
              <>
                <Initials name={item.providerName} />
                <Text multilineEllipses={1}>{item.providerName}</Text>
              </>
            )}
          </ListItemInfo>

          {onRescheduledClick && (
            <Button label="Reschedule" category="danger" onClick={() => onRescheduledClick(item)} />
          )}
        </ListItem>
      ))}
    </Container>
  );
};
