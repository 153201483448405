import React, { useState } from 'react';

import { Heading } from 'components/v2/Typography';
import { Appointment } from 'kb-shared';
import { ModalCancelResched } from 'screens/ModalCancelResched/ModalCancelResched';

import { List } from '../List/List';
import { Container } from './UpcomingAppointments.styled';
import { getFutureAppointments } from './UpcomingAppointments.utils';

export const UpcomingAppointments = ({ appointments }: { appointments?: Appointment[] }) => {
  const [selectedAppointmentToDelete, setSelectedAppointmentToDelete] = useState<
    Appointment | undefined
  >();

  const futureAppointments = getFutureAppointments(appointments);

  if (futureAppointments.length === 0) return <></>;

  return (
    <Container>
      <Heading tag="div" styledAs="h3">
        Upcoming Appointments
      </Heading>

      <List
        appointments={futureAppointments}
        onRescheduledClick={appointment => setSelectedAppointmentToDelete(appointment)}
      />

      {selectedAppointmentToDelete && (
        <ModalCancelResched
          visible={!!selectedAppointmentToDelete}
          onClose={() => setSelectedAppointmentToDelete(undefined)}
          appointment={selectedAppointmentToDelete}
          onAppointmentCancelled={() => window.location.reload()}
        />
      )}
    </Container>
  );
};
