import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5em;
  gap: 1.5em;
  border-radius: var(--radius-lg-8, 8px);
  border: 1px dashed ${({ theme }) => theme.colors.neutral.lighterNavy};
  background: ${({ theme }) => theme.colors.neutral.lavenderLight};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5em;
`;
