import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';

import { EmployerWidgetCTA } from '../EmployerWidgets.types';
import { ActionContainer, ActionTextContainer } from './EmployerWidgetActions.styled';

export const EmployerWidgetActions = ({
  actions
}: {
  actions: EmployerWidgetCTA[];
}): JSX.Element => (
  /*Fragment is needed here to prevent following error in SaicWidget and EmbryoIncWidget: 
  'EmployerWidgetActions' cannot be used as a JSX component.
    Its return type 'Element[]' is not a valid JSX element.
      Type 'Element[]' is missing the following properties from type 'Element' */
  <>
    {actions.map((action, index) => (
      <ActionContainer $last={index === actions.length - 1} key={`cta-${index}`}>
        <ActionTextContainer>
          <Text fontStyle="bold">{action.line1}</Text>
        </ActionTextContainer>
        <Button
          category="secondary"
          label={action.linkText}
          onClick={() => window.open(action.linkUrl, '_blank')}
        />
      </ActionContainer>
    ))}
  </>
);
