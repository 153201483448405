import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import { pageUrl } from 'utilities/pageUrl';

import { Content, Description } from './NoAppointment.styled';

export const NoAppointment = () => {
  const history = useHistory();

  return (
    <div>
      <Content>
        <Description>
          <Text size="lg" color={theme.colors.neutral.navy} fontStyle="semibold">
            You Have No Upcoming Appointments!
          </Text>
          <Text size="xs" color={theme.colors.neutral.navy}>
            When you book an appointment, it will appear here.
          </Text>
        </Description>

        <Button
          category="primary"
          label="Schedule Appointment"
          onClick={() => history.push(pageUrl.booking.bookingQuiz())}
          size="md"
        />
      </Content>
    </div>
  );
};
